import React from "react"
import useProgressiveImg from "./ProgressiveImage"

const LazyImage = props => {
  const [src, { blur }] = useProgressiveImg(props.lowRes, props.highRes)
  return (
    <img
      src={src}
      style={{
        filter: blur ? "blur(20px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
      }}
      alt="loading.."
      {...props}
    />
  )
}

export default LazyImage
