import React from "react"

export default function DonateCustom(props) {
  const [noOfDonation, setNoOfDonation] = React.useState("")

  return (
    <div>
      <br />
      <h4 className="d-flex justify-content-center">
        <button
          className="btn btn-outline-secondary mb-3"
          onClick={props.chooseCriteria}
          style={{ color: "rgba(37, 108, 100, 1)", display: "flex" }}
        >
          <span class="material-icons">arrow_back</span>Choose donation criteria
        </button>
      </h4>
      <div
        className="container donate-card mt-3 justify-content-center"
        style={{ boxShadow: "0px 0px 20px #cccccc" }}
      >
        <h3 className="text-center m-5" style={{ color: "#444444" }}>
          <b>Donate as you wish</b>
        </h3>

        <div className="row  justify-content-center align-items-center">
          <div class="input-group mb-3 m-2 col-md-8">
            <span class="input-group-text">$</span>
            <input
              type="number"
              class="form-control"
              placeholder="Donation amount in USD"
              value={noOfDonation}
              onChange={e => setNoOfDonation(e.target.value)}
            />
          </div>
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="btn btn-specialMeal">Make donation</div>
        </div>
        <br />
      </div>
    </div>
  )
}
