import React, { useState } from "react"
import "../assets/style.scss"
import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"
import donate_bg from "../images/donate_bg.webp"
import donate_bg_low from "../images/donate_bg_low.webp"
import donate_scholarship from "../images/donate_scholarship.svg"
import donate_learning from "../images/donate_learning.svg"
import donate_eggucation from "../images/donate_eggucation.svg"
import donate_wish from "../images/donate_wish.svg"
import homeRightVector from "../images/homeRightVector.svg"
import PrimarySchoolScholarchip from "../components/PrimarySchoolScholarchip"
import HighSchoolScholarship from "../components/HighSchoolScholarship"
import EverydayMeal from "../components/EverydayMeal"
import DonateCustom from "../components/DonateCustom"
import LazyImage from "../components/LazyImage"

const DonatePage = props => {
  const [allCards, setAllCards] = useState(true)
  const [primaryScholarship, setPrimaryScholarship] = useState(false)
  const [highScholarship, setHighScholarship] = useState(false)
  const [everydayMeal, setEverydayMeal] = useState(false)
  const [specialMeal, setSpecialMeal] = useState(false)

  const changeCard = () => {
    setAllCards(false)
    setPrimaryScholarship(false)
    setHighScholarship(false)
    setEverydayMeal(false)
    setSpecialMeal(false)
  }
  return (
    <div>
      <SEO title="Donate" />
      <Header currentPage="donate" />
      <div className="m-top">
        <LazyImage
          lowRes={donate_bg_low}
          highRes={donate_bg}
          alt="donateBackground"
          className="position-absolute  fade-in"
          style={{ top: 200, zIndex: "-1" }}
          width="100%"
        ></LazyImage>
      </div>

      <div className="position-relative slideshow1right">
        <img
          src={homeRightVector}
          class="position-absolute blobRight "
          style={{ right: 0, top: 250 }}
          alt="homeRightVector"
        ></img>
      </div>

      <div className="container">
        <div className="text-center" style={{ zIndex: 99 }}>
          <h1>
            Your small help can make a <b>great impact</b>
          </h1>
        </div>
      </div>
      {allCards ? (
        <div className="container fade-in">
          <div className="row mt-5">
            <div className="col-md-3">
              <button
                class="no-style-btn story-card text-left"
                style={{ boxShadow: "0px 0px 20px #cccccc" }}
                onClick={() => {
                  changeCard()
                  setPrimaryScholarship(true)
                }}
              >
                <img src={donate_scholarship} alt="card" />
                <div className="card-text-2">
                  <h5>Primary School Scholarship </h5>
                  <h1 className="mt-3 font-weight-bold">$25</h1>
                  <h6>For a month</h6>
                </div>
              </button>
            </div>
            <div className="col-md-3">
              <button
                class="no-style-btn story-card text-left"
                style={{ boxShadow: "0px 0px 20px #cccccc" }}
                onClick={() => {
                  changeCard()
                  setHighScholarship(true)
                }}
              >
                <img src={donate_learning} alt="card" />
                <div className="card-text-2">
                  <h5>High School Scholarship </h5>
                  <h1 className="mt-3 font-weight-bold">$35</h1>
                  <h6>For a month</h6>
                </div>
              </button>
            </div>
            <div className="col-md-3">
              <button
                class="no-style-btn story-card text-left"
                style={{ boxShadow: "0px 0px 20px #cccccc" }}
                onClick={() => {
                  changeCard()
                  setEverydayMeal(true)
                }}
              >
                <img src={donate_eggucation} alt="card" />
                <div className="card-text-2">
                  <h5>
                    Everyday
                    <br />
                    Meal
                  </h5>
                  <h1 className="mt-3 font-weight-bold">$25</h1>
                  <h6>For a day</h6>
                </div>
              </button>
            </div>
            <div className="col-md-3">
              <button
                class="no-style-btn story-card text-left"
                style={{ boxShadow: "0px 0px 20px #cccccc" }}
                onClick={() => {
                  window.location.replace(
                    `https://www.paypal.com/donate?business=GKC8EB42LECTL&item_name=Thanks+for+your+contribution+for+Swapnanagar&currency_code=USD`
                  )
                }}
              >
                <img src={donate_wish} alt="card" />
                <div className="card-text-2 text-dark">
                  <h5>
                    Donate
                    <br />
                    as you wish
                  </h5>
                </div>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {primaryScholarship ? (
        <PrimarySchoolScholarchip
          chooseCriteria={() => {
            setPrimaryScholarship(false)
            setAllCards(true)
          }}
        />
      ) : null}
      {highScholarship ? (
        <HighSchoolScholarship
          chooseCriteria={() => {
            setHighScholarship(false)
            setAllCards(true)
          }}
        />
      ) : null}
      {everydayMeal ? (
        <EverydayMeal
          chooseCriteria={() => {
            setEverydayMeal(false)
            setAllCards(true)
          }}
        />
      ) : null}
      {specialMeal ? (
        <DonateCustom
          chooseCriteria={() => {
            setSpecialMeal(false)
            setAllCards(true)
          }}
        />
      ) : null}

      <div style={{ marginTop: "50rem" }} />
      <Footer />
    </div>
  )
}
export default DonatePage
