import React, { useState } from "react"

export default function HighSchoolScholarship(props) {
  const [noOfDonation, setNoOfDonation] = useState(1)

  return (
    <div>
      <br />
      <h4 className="d-flex justify-content-center">
        <button
          className="btn btn-outline-secondary mb-3"
          onClick={props.chooseCriteria}
          style={{ color: "rgba(37, 108, 100, 1)", display: "flex" }}
        >
          <span class="material-icons">arrow_back</span>Choose donation criteria
        </button>
      </h4>
      <div
        className="container donate-card mt-3 justify-content-center"
        style={{ boxShadow: "0px 0px 20px #cccccc" }}
      >
        <h3 className="text-center m-5" style={{ color: "#444444" }}>
          <b>Donate for High School Scholarship</b>
        </h3>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="primary-scholarship text-center d-flex flex-column align-items-center justify-content-center"
            style={{
              background:
                "linear-gradient(180deg, rgba(117, 227, 214, 0.74) 0%, rgba(117, 227, 214, 0.22) 100%)",
            }}
          >
            <h1 style={{ color: "#444444" }}>
              <b>${35 * noOfDonation}</b>
            </h1>
            <div style={{ fontSize: "0.75rem" }}>per month</div>
          </div>
          <div className="ml-3 d-flex flex-column justify-content-center align-items-center">
            <h3 className="text-center mb-2" style={{ color: "#444444" }}>
              <b>for</b>
            </h3>
            <div className="d-flex align-items-center justify-content-center">
              <h3 className="px-2">
                <button
                  className="no-style-btn"
                  onClick={() => {
                    if (noOfDonation > 1) {
                      setNoOfDonation(noOfDonation - 1)
                    }
                  }}
                >
                  -
                </button>
              </h3>

              <div
                className="small-circle d-flex align-items-center justify-content-center"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(117, 227, 214, 0.74) 0%, rgba(117, 227, 214, 0.22) 100%)",
                }}
              >
                <h3 className="pt-1" style={{ color: "#444444" }}>
                  <b>{noOfDonation}</b>
                </h3>
              </div>
              <h3 className="px-2">
                <button
                  className="no-style-btn"
                  onClick={() => {
                    setNoOfDonation(noOfDonation + 1)
                  }}
                >
                  +
                </button>
              </h3>
            </div>
            <h3 className="pt-2 text-center" style={{ color: "#444444" }}>
              <b>child</b>
            </h3>
          </div>
        </div>
        <br />
        {/* <br />
        <div className="d-flex justify-content-center">
          <a
            href={`https://www.paypal.com/donate?business=GKC8EB42LECTL&item_name=Thanks+for+your+contribution+for+Swapnanagar&currency_code=USD`}
            className="btn btn-primary "
          >
            Make monthly donation
          </a>
        </div> */}
        <br />
        <div className="d-flex justify-content-center">
          <a
            href={`https://www.paypal.com/donate?business=GKC8EB42LECTL&item_name=Thanks+for+your+contribution+for+Swapnanagar&currency_code=USD&amount=${
              noOfDonation * 35
            }`}
            className="btn btn-success"
          >
            &nbsp;&nbsp;&nbsp; Donate ${noOfDonation * 35} now &nbsp; &nbsp;
            &nbsp;
          </a>
        </div>
      </div>
    </div>
  )
}
